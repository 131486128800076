import { AxiosError } from "axios";
import useChatStore from "../store";
import * as Sentry from "@sentry/react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface TSendMessageProps {
	file?: Blob | null;
	text: string;
	onError: (error: Error) => void;
	projectToken?: string|null;
}

const ERROR_STATUSES = {
	CHAT_EXPIRED: 445,
};

const useServerMessageHandler = () => {
	const {
		setSeen,
		setReceived,
		setInputsDisabled,
		setStatusOnLastMessage,
		updateLastMessage,
		refreshChatHistory,
		refreshTimeout,
		refreshMessage
	} = useChatStore();
	const messageHandler = async (messageData: TResponseHandlerProps, timeout: NodeJS.Timeout) => {
		const {
			data: { message },
			event,
		} = messageData;

		clearTimeout(timeout);
		if (event === "SEEN") {
			return setSeen();
		}
		if (event === "STATUS") {
			return setStatusOnLastMessage(message);
		}
		if (event === "MESSAGE") {
			updateLastMessage(message);
			localStorage.removeItem('last_user_message');
			return setInputsDisabled(false);
		}
		if (event === "MESSAGE_RECEIVED") {
			return setReceived();
		}
		if (event === "TIMEOUT") {
			await refreshChatHistory?.();
			toast.error("Oops! Looks like we're having trouble processing your message. Please try resending it", {
				autoClose: 10000,
			})
			return refreshTimeout();
		}
		if (event === "ERROR") {
			return refreshMessage(message);
		}
		return null;
	};

	return messageHandler;
};

function parse(source: AllowSharedBufferSource): Record<string, unknown> | undefined {
	const textDecoder = new TextDecoder("utf-8");
	const decoded = textDecoder.decode(source);
	if (decoded === "\n") {
		return undefined;
	}
	const lines = decoded.split("\n");
	const message: Record<string, unknown> = {};

	lines.forEach((line) => {
		const index = line.indexOf(":");
		const field = line.substring(0, index);
		const value = line.substring(index + 1);
		if (field) {
			message[field] = field === "data" ? JSON.parse(value) : value;
		}
	});

	return message;
}
const useSendMessage = () => {
	const messageHandler = useServerMessageHandler();
	const { currentProjectToken, refreshChatHistory } = useChatStore((state) => state);

	const sendMessage = async ({ file, text, onError, projectToken }: TSendMessageProps) => {
		const timeout = setTimeout(() => {
			refreshChatHistory?.();
		}, 50000);
		const formdata = new FormData();
		if (file) {
			formdata.append("file", file);
		}
		formdata.append("text", text);

		try {
			const selectedProjectToken = currentProjectToken || projectToken;
			const sse = await fetch(`${import.meta.env.VITE_CHAT_URL}/chat/send`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${selectedProjectToken}`,
				},
				redirect: "follow",
				body: formdata,
			});

			if (sse.body && sse.body.getReader) {
				const reader = sse.body.getReader();

				const readChunk = async () => {
					const { done, value } = await reader.read();

					if (done) {
						reader.releaseLock();
						return;
					}
					const message = parse(value) as TResponseHandlerProps | undefined;
					if (message) messageHandler(message, timeout);

					readChunk();
				};

				readChunk();
			}
		} catch (error) {
			const axiosError = error as AxiosError;
			const chatError = axiosError?.response?.status;
			if (chatError === ERROR_STATUSES.CHAT_EXPIRED) {
				return window.location.href = `/chat/error?error_status=${ERROR_STATUSES.CHAT_EXPIRED}`;
			}
			Sentry.captureException(error);
			onError(error as AxiosError);
		}
	};

	return sendMessage;
};

export default useSendMessage;
