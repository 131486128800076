import Image from 'chat-ui/src/components/Image/Image';
import useTheme from 'chat-ui/src/hooks/useTheme';
import TelletLogo from '@/assets/tellet-logo-main-white.svg';
import EmpathyLogo from '@/assets/empaty-logo.svg';
import { ECompanyThemeName } from '@/enums/enums';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="h-full m-auto w-36 animate-pulse" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="h-full m-auto w-52 animate-pulse" />,
};

function FullPageLoader() {
  const { themeName, currentTheme } = useTheme();

  return (
    <div
      style={{ backgroundColor: currentTheme?.['chat-consent-bg'] }}
      className="fixed inset-0 flex items-center justify-center w-full h-full text-center z-100"
    >
      <div className="flex flex-col items-center justify-center">
        {themeLogosList[themeName]}
      </div>
    </div>
  );
}

export default FullPageLoader;
