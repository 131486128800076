/* eslint-disable react/require-default-props */
import axios from 'axios';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import ArrowPathIcon from '../chatUI/src/components/icons/ArrowPathIcon';
import ChevronDoubleDownIcon from '../chatUI/src/components/icons/ChevronDoubleDownIcon';
import PlayIcon from '../chatUI/src/components/icons/PlayIcon';
import ChatInput from './src/components/input/ChatInput';
import TextMessage from "./src/components/messages/text/TextMessage";
import { Button } from './src/components/shadcn/button';
import TooltipWrapper from './src/components/tooltip/TooltipWrapper';
import useTheme from './src/hooks/useTheme';
import { cn } from './src/lib/utils';
import useChatStore from './src/store';
import { AxiosError } from 'axios';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

enum EChatErrors {
  PROJECT_IS_COMPLETED = 442,
  PROJECT_IS_DRAFT_OR_READY = 425,
  PROJECT_DO_NOT_EXIST = 404,
  MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED = 433,
  CHAT_EXPIRED = 445,
  METADATA_REQUIRED = 434,
  INVALID_LANGUAGE = 435,
}

enum ECompanyThemeName {
  DEFAULT = 'default',
  EMPATHY = 'empathyiq',
}

interface StandAloneChatProps {
  chatHeight: string;
  fullScreen?: boolean;
  restartChatButton?: boolean;
  handleRestartChat?: () => void;
  isTestChat: boolean;
  current_conversation_id?: string;
  isRefreshLoading: boolean;
}


interface ProgressData {
  count: number;
  limit: number;
  done: boolean;
}

const themeNamesList = {
  [ECompanyThemeName.DEFAULT]: 'Tellet',
  [ECompanyThemeName.EMPATHY]: 'Project',
};


const axiosInstanceChat = axios.create({
  baseURL: import.meta.env.VITE_CHAT_URL,
});


function ChatPresentation({
  chatHeight,
  restartChatButton = false,
  handleRestartChat,
  isTestChat,
  isRefreshLoading
}: StandAloneChatProps) {
  const { project_id } = useParams()
  const { messages, isLastMessageRerendered, currentProjectToken } = useChatStore((state) => state);
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const [isVoiceOpened, setIsVoiceOpened] = useState(false)
  const [searchParams] = useSearchParams();
  const chatEnvironment = searchParams.get('environment');
  const isSandboxChat = chatEnvironment === 'sandbox';
  const { currentTheme, themeName } = useTheme();
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const [progressData, setProgressData] = useState<ProgressData | null>(null)
  const navigate = useNavigate();
  const current_conversation_id = localStorage.getItem(`current_conversation_id/test/${project_id}`);

  const fetchChatProgress = async () => {
    try {
      if (currentProjectToken) {
        const response = await axiosInstanceChat.get('/chat/progress', {
          headers: {
            Authorization: `Bearer ${currentProjectToken}`,
          },
        });
        setProgressData(response.data);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const chatError = axiosError?.response?.status;
      if (chatError === EChatErrors.CHAT_EXPIRED) {
        return navigate(`/chat/error?error_status=${EChatErrors.CHAT_EXPIRED}`);
      }
      console.error(err);
    }
  };

  const fetchTestChatProgress = async () => {
    try {
      if (currentProjectToken && current_conversation_id) {
        const response = await axiosInstanceChat.get('/chat/test/progress', {
          headers: {
            Authorization: `Bearer ${currentProjectToken}`,
          },
        });
        setProgressData(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleBackToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight + 64;
    }
  };

  // show scroll to bottom button
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight
      < 350
    ) {
      setShowBackToBottom(false);
    } else {
      setShowBackToBottom(true);
    }
  };

  useLayoutEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isLastMessageRerendered]);

  useEffect(() => {
    handleBackToBottom();
  }, [isVoiceOpened])

  useEffect(() => {
    if (isTestChat) {
      fetchTestChatProgress()
    } else {
      fetchChatProgress()
    }
  }, [messages])

  const progressCount: number | undefined = progressData?.count;
  const progressLimit: number | undefined = progressData?.limit;
  const isProgressDone: boolean | undefined = progressData?.done;
  const percentage = (progressCount && progressLimit) ? (progressCount / progressLimit) * 100 : 0;

  return (
    <div className="flex relative flex-col items-center w-full px-2 max-w-[1000px] mx-auto min500:px-0">
      <ToastContainer position="top-center" className={cn(isTestChat && 'mt-10', isSandboxChat && !isTestChat && 'mt-14', !isTestChat && 'mt-8')} closeOnClick />
      {/* chat ui */}
      <div className={cn("relative w-full h-full", (!isTestChat && messages?.length > 4) && 'pb-8')}>
        {/* flag */}
        {!isTestChat && isSandboxChat &&
          <div
            style={{ backgroundColor: currentTheme?.['chat-primary'] }}
            className='absolute left-0 right-0 z-50 flex items-center justify-center w-full text-xs font-medium text-white shadow-md min500:rounded-t-none min500:top-0 h-7 rounded-t-xl top-3 bg-chat-primary'>
            Test chat
          </div>}

        {/* progress bar */}
        <div
          style={{ backgroundColor: currentTheme?.['chat-progress-bar-bg'] }}
          className={cn('absolute left-0 right-0 min500:top-7 border-t-2 border-white overflow-hidden z-50 w-full h-6 top-10',
            !isSandboxChat && 'top-3 min500:rounded-t-none rounded-t-lg min500:top-0 border-none'
          )}>
          <div style={{
            width: `${percentage ?? 0}%`,
            backgroundColor: currentTheme?.['primary']
          }}
            className={cn('flex w-full  justify-center items-center h-6 transition-all duration-700 ease-in-out rounded-r-full',
              percentage === 100 && 'rounded-r-none'
            )}>
            {isProgressDone &&
              <p className={cn('text-xs font-medium text-white transition-all ease-in-out duration-500')}>
                {themeNamesList[themeName]} is done 🥳</p>}
          </div>
        </div>
        {/* restart test chat */}
        {restartChatButton && (
          <TooltipWrapper text="Start conversation from the start." className="">
            <Button
              variant="default"
              onClick={handleRestartChat}
              className="absolute z-50 transition-all border-2 border-white shadow-xl top-11 right-4"
            >
              {messages?.length === 0 ? <PlayIcon className='size-5' /> :
                <ArrowPathIcon className={cn('size-5', isRefreshLoading && 'animate-spin')} />}
            </Button>
          </TooltipWrapper>
        )}

        {/* restart sandbox chat */}
        {isSandboxChat && (
          <TooltipWrapper text="Start conversation from the start." className="">
            <Button
              variant="default"
              onClick={handleRestartChat}
              className="absolute z-50 border-2 border-white shadow-xl top-[70px] right-4 transition-all"
            >
              {messages?.length === 0 ? <PlayIcon className='size-5' /> : <ArrowPathIcon className={cn('size-5', isRefreshLoading && 'animate-spin')} />}
            </Button>
          </TooltipWrapper>
        )}
        <div
          ref={chatBoxRef}
          onScroll={handleScroll}
          className={`w-full mt-3 min500:mt-0 min500:rounded-none min500:border-none ${isVoiceOpened && "pb-[33vh]"} scroll-smooth relative min500:border-none min500:shadow-none border-2 rounded-xl shadow overflow-hidden overflow-y-auto 
          ${isTestChat ? 'bg-gray-50' : 'bg-white'} 
          ${chatHeight || ''}`}
        >
          <div className="w-full">
            {/* chat */}
            <div className={cn("flex flex-col w-full h-full px-3 md700:px-1.5", isTestChat ? 'bg-gray-50' : 'bg-white')}>
              {/* chat boxes */}
              <div className="flex flex-col items-center w-full h-full px-2">
                {/* messages */}
                <div className={cn("flex flex-col w-full gap-5 mt-8 mb-3 h-fit", isSandboxChat && 'mt-16')}>
                  {messages?.map((item, index) => <TextMessage isTestChat={isTestChat} key={item._id} {...item}
                    isLastMessage={index === messages?.length - 1}
                  />)}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      {/*  back to bottom */}
      {
        showBackToBottom && (
          <div className="relative w-full">
            <button
              onClick={handleBackToBottom}
              type="button"
              style={{ backgroundColor: currentTheme?.primary }}
              className="absolute right-0.5 bottom-[calc(100%+100px)] rounded-l-xl text-white w-10 h-10 flex items-center justify-center"
            >
              <ChevronDoubleDownIcon className='size-5' />
            </button>
          </div>
        )
      }
      <div className="flex items-center w-full mb-2 md600:flex-col">
        <div className={cn('w-full', isTestChat && 'mt-4')}>
          <ChatInput isTestChat={isTestChat} setIsVoiceOpened={setIsVoiceOpened} />
        </div>
      </div>
    </div >
  );
}

export default ChatPresentation;
