import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Chat from '@/components/chat/Chat';
import ConsentScreen from '@/components/chat/ConsentScreen';

function ChatPage() {
  const projectId = window.location.pathname;
  if (projectId) projectId.replace('/', '');
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const { currentTheme } = useTheme();

  const [projectConsent, setProjectConsent] = useState(false);

  useEffect(() => {
    const localStorageConsent = localStorage.getItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`);
    setProjectConsent(localStorageConsent === 'true');
  }, [projectId, sandboxLink]);

  if (!projectConsent) return <ConsentScreen projectId={projectId} setProjectConsent={setProjectConsent} />;

  return (
    <div
      className="min450:bg-white chat-page"
    >
      <div
        style={{ backgroundColor: currentTheme?.['chat-background'] }}
        className="fixed top-0 bottom-0 left-0 right-0 w-full h-full min500:!bg-white"
      >
      </div>
      <Chat chatHeight="h-[calc(100dvh-95px)] scrollbar-hidden min500:h-[calc(100dvh-85px)]" projectId={projectId} />
    </div>
  );
}

export default ChatPage;
